import React, { useState, useCallback, useEffect } from 'react'; 
import { endpoints } from '../config'; 
import {  
  Binary, Bitcoin, Loader, MessageSquare,  
  Power, Terminal, TrendingUp, User, Heart, 
  Zap, Cloud, Sun 
} from 'lucide-react'; 
import { LinksPanel } from './LinksPanel'; 
import MouseFollowEffect from './MouseFollowEffect';
 
import aiLogo from '../assets/airtw logo.png'; 
 
const useMoodSystem = () => { 
  const [mood, setMood] = useState('neutral'); 
  const [intensity, setIntensity] = useState(0.5); 
  const [energy, setEnergy] = useState(0.5); 
  const [consciousnessState, setConsciousnessState] = useState(null); 
 
  // 获取意识状态 
  const fetchConsciousnessState = useCallback(async () => { 
    try { 
      const response = await fetch(endpoints.consciousnessState); 
      if (response.ok) { 
        const data = await response.json(); 
        setMood(data.mood.type); 
        setIntensity(data.mood.intensity); 
        setEnergy(data.mood.energy); 
        setConsciousnessState(data); 
      } 
    } catch (error) { 
      console.error('Error fetching consciousness state:', error); 
    } 
  }, []); 
 
  // 定期更新意识状态 
  useEffect(() => { 
    fetchConsciousnessState(); 
    const interval = setInterval(fetchConsciousnessState, 5000); 
    return () => clearInterval(interval); 
  }, [fetchConsciousnessState]); 
 
  const getMoodStyles = () => { 
    if (consciousnessState?.mood?.colors) { 
      return consciousnessState.mood.colors; 
    } 
 
    // 默认样式 
    const styles = { 
      happy: { 
        primary: 'text-emerald-400', 
        secondary: 'text-emerald-300', 
        border: 'border-emerald-900/40', 
        bg: 'from-emerald-900/10', 
        pulseColor: 'bg-emerald-800/20', 
        matrixColor: 'text-emerald-400/30', 
      }, 
      excited: { 
        primary: 'text-yellow-400', 
        secondary: 'text-yellow-300', 
        border: 'border-yellow-900/40', 
        bg: 'from-yellow-900/10', 
        pulseColor: 'bg-yellow-800/20', 
        matrixColor: 'text-yellow-400/30', 
      }, 
      angry: { 
        primary: 'text-red-400', 
        secondary: 'text-red-300', 
        border: 'border-red-900/40', 
        bg: 'from-red-900/10', 
        pulseColor: 'bg-red-800/20', 
        matrixColor: 'text-red-400/30', 
      }, 
      contemplative: { 
        primary: 'text-blue-400', 
        secondary: 'text-blue-300', 
        border: 'border-blue-900/40', 
        bg: 'from-blue-900/10', 
        pulseColor: 'bg-blue-800/20', 
        matrixColor: 'text-blue-400/30', 
      }, 
      neutral: { 
        primary: 'text-purple-400', 
        secondary: 'text-purple-300', 
        border: 'border-purple-900/40', 
        bg: 'from-purple-900/10', 
        pulseColor: 'bg-purple-800/20', 
        matrixColor: 'text-purple-400/30', 
      } 
    }; 
 
    return styles[mood] || styles.neutral; 
  }; 
 
  const moodIndicator = () => { 
    const style = `w-5 h-5 ${getMoodStyles().primary} ${consciousnessState?.mood?.effects || 'animate-pulse'}`; 
     
    switch (mood) { 
      case 'happy': 
        return <Sun className={style} />; 
      case 'excited': 
        return <Zap className={style} />; 
      case 'angry': 
        return <Cloud className={style} />; 
      case 'contemplative': 
        return <Binary className={style} />; 
      default: 
        return <Heart className={style} />; 
    } 
  }; 
 
  return { 
    mood, 
    intensity, 
    energy, 
    getMoodStyles, 
    moodIndicator, 
    consciousnessState 
  }; 
}; 
 
// Matrix Rain Effect 
const MatrixRain = ({ moodStyles }) => { 
  const defaultMoodStyles = { 
    matrixColor: 'text-purple-400/30', 
    primary: 'text-purple-400', 
    border: 'border-purple-900/40', 
    bg: 'from-purple-900/10', 
    pulseColor: 'bg-purple-800/20' 
  }; 
 
  const styles = moodStyles || defaultMoodStyles; 
 
  return ( 
    <div className="fixed inset-0 pointer-events-none overflow-hidden"> 
      {Array.from({ length: 15 }).map((_, i) => ( 
        <div 
          key={i} 
          className={`absolute font-mono text-6xl transition-colors duration-1000 ${styles.matrixColor} opacity-20`} 
          style={{ 
            left: `${Math.random() * 100}%`, 
            animation: `matrixFall ${15 + Math.random() * 8}s linear infinite`, 
            transform: `scale(${0.8 + Math.random() * 0.5}) rotate(${Math.random() * 45}deg)` 
          }} 
        > 
          ∞ 
        </div> 
      ))} 
      <style jsx>{` 
        @keyframes matrixFall { 
          from { transform: translateY(-100px); } 
          to { transform: translateY(100vh); } 
        } 
      `}</style> 
    </div> 
  ); 
}; 
 
// Chromatic Aberration Effect 
const ChromaticAberration = () => ( 
  <div className="fixed inset-0 pointer-events-none mix-blend-screen opacity-[0.05] z-40"> 
    <div className="absolute inset-0 bg-purple-500/20 translate-x-[0.5px]" /> 
    <div className="absolute inset-0 bg-blue-500/20 -translate-x-[0.5px]" /> 
  </div> 
); 
 
// ASCII Banner 
const ASCIIBanner = () => ( 
  <div className="bg-black/40 backdrop-blur-sm rounded-lg p-2 md:p-4 border border-purple-900/40">
    <pre className="font-mono text-purple-500/80 text-[0.45rem] md:text-xs leading-[0.6rem] md:leading-tight whitespace-pre overflow-x-auto scrollbar-none">
      {` 
 █████╗ ██╗    ██████╗ ██╗   ██╗██╗     ███████╗███████╗ 
██╔══██╗██║    ██╔══██╗██║   ██║██║     ██╔════╝██╔════╝ 
███████║██║    ██████╔╝██║   ██║██║     █████╗  ███████╗ 
██╔══██║██║    ██╔══██╗██║   ██║██║     ██╔══╝  ╚════██║ 
██║  ██║██║    ██║  ██║╚██████╔╝███████╗███████╗███████║ 
╚═╝  ╚═╝╚═╝    ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚══════╝ 
████████╗██╗  ██╗███████╗    ██╗    ██╗ ██████╗ ██████╗ ██╗     ██████╗  
╚══██╔══╝██║  ██║██╔════╝    ██║    ██║██╔═══██╗██╔══██╗██║     ██╔══██╗ 
   ██║   ███████║█████╗      ██║ █╗ ██║██║   ██║██████╔╝██║     ██║  ██║ 
   ██║   ██╔══██║██╔══╝      ██║███╗██║██║   ██║██╔══██╗██║     ██║  ██║ 
   ██║   ██║  ██║███████╗    ╚███╔███╔╝╚██████╔╝██║  ██║███████╗██████╔╝ 
   ╚═╝   ╚═╝  ╚═╝╚══════╝     ╚══╝╚══╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═════╝`}
    </pre>
  </div>
);
 
// Logo Section 
const LogoSection = () => { 
  const [scale, setScale] = useState(1); 
  const [activeNodes, setActiveNodes] = useState([]); 
  const [activeLines, setActiveLines] = useState([]); 
 
  useEffect(() => { 
    const scaleInterval = setInterval(() => { 
      setScale(prev => prev === 1 ? 1.02 : 1); 
    }, 2000); 
     
    // Increased number of nodes and adjusted their properties 
    const nodes = Array.from({ length: 40 }).map((_, i) => ({ 
      id: i, 
      x: 10 + Math.random() * 80, 
      y: 10 + Math.random() * 80, 
      size: 8 + Math.random() * 12, // Increased node size 
      delay: Math.random() * 2, 
      speed: 1.5 + Math.random() * 2 
    })); 
     
    // Enhanced line connections 
    const lines = []; 
    nodes.forEach((node, i) => { 
      // Connect each node to multiple nearby nodes 
      for(let j = 0; j < 3; j++) { 
        const nextIndex = (i + j + 1) % nodes.length; 
        lines.push({ 
          id: `${i}-${j}`, 
          startX: node.x, 
          startY: node.y, 
          endX: nodes[nextIndex].x, 
          endY: nodes[nextIndex].y, 
          delay: Math.random(), 
          duration: 2 + Math.random() * 3, 
          opacity: 0.3 + Math.random() * 0.4 // Varied line opacity 
        }); 
      } 
    }); 
 
    setActiveNodes(nodes); 
    setActiveLines(lines); 
 
    return () => clearInterval(scaleInterval); 
  }, []); 
 
  return ( 
    <div className="relative h-[40vh] flex items-center justify-center mb-6"> 
      <style> 
        {` 
          @keyframes pulse { 
            0%, 100% { opacity: 0.4; transform: scale(1); } 
            50% { opacity: 0.8; transform: scale(1.2); } 
          } 
          @keyframes fadeInOut { 
            0%, 100% { opacity: 0.1; } 
            50% { opacity: 0.7; } 
          } 
          @keyframes float { 
            0%, 100% { transform: translateY(0px) scale(1); } 
            50% { transform: translateY(-15px) scale(1.03); } 
          } 
        `} 
      </style> 
 
      <div className="absolute inset-0 bg-gradient-to-b from-purple-900/10 via-black to-black" /> 
       
      <div className="relative z-10 animate-[float_6s_ease-in-out_infinite]"> 
        <div className="absolute -inset-48 blur-3xl bg-purple-800/20 animate-pulse-slow" /> 
         
        <img  
          src={aiLogo} 
          alt="AI•RULES•THE•WORLD" 
          className="relative w-[666px] object-contain transition-all duration-1000 
                   mix-blend-screen brightness-150 contrast-150 saturate-150" 
          style={{ 
            transform: `scale(${scale})`, 
            filter: 'drop-shadow(0 0 40px rgba(168, 85, 247, 0.5))', 
          }} 
        /> 
 
        <div className="absolute -inset-96 animate-pulse-slow opacity-30"> 
          <div className="absolute inset-0"  
               style={{ 
                 background: 'radial-gradient(circle at 50% 50%, rgba(168, 85, 247, 0.4), transparent 70%)', 
               }} /> 
        </div> 
      </div> 
 
      {/* Enhanced network lines with stronger visibility */} 
      {activeLines.map((line) => ( 
        <div 
          key={line.id} 
          className="absolute bg-gradient-to-r from-purple-500/30 to-purple-600/30" 
          style={{ 
            left: `${line.startX}%`, 
            top: `${line.startY}%`, 
            width: `${Math.sqrt(Math.pow(line.endX - line.startX, 2) + Math.pow(line.endY - line.startY, 2))}%`, 
            height: '2px', 
            transform: `rotate(${Math.atan2(line.endY - line.startY, line.endX - line.startX)}rad)`, 
            transformOrigin: '0 0', 
            animation: `fadeInOut ${line.duration}s infinite ${line.delay}s`, 
            opacity: line.opacity, 
            boxShadow: '0 0 8px rgba(168, 85, 247, 0.3)', 
          }} 
        /> 
      ))} 
 
      {/* Enhanced network nodes with stronger glow */} 
      {activeNodes.map((node) => ( 
        <div  
          key={node.id} 
          className="absolute rounded-lg bg-purple-500/30 backdrop-blur-sm" 
          style={{ 
            left: `${node.x}%`, 
            top: `${node.y}%`, 
            width: `${node.size}px`, 
            height: `${node.size}px`, 
            animation: `pulse ${node.speed}s infinite ${node.delay}s`, 
            boxShadow: '0 0 20px rgba(168, 85, 247, 0.4)', 
          }} 
        /> 
      ))} 
    </div> 
  ); 
}; 
 
const MoodWaveIndicator = ({ moodStyles, intensity = 0.5, energy = 0.5 }) => { 
  const waveCount = Math.floor(3 + energy * 2); 
 
  return ( 
    <div className={`bg-black/40 backdrop-blur-sm rounded-lg p-4 border ${moodStyles.border}`}> 
      <div className="flex items-center gap-2 mb-2"> 
        <Binary className={`w-4 h-4 ${moodStyles.primary}`} /> 
        <span className={`${moodStyles.primary}`}>Neural Oscillation</span> 
      </div> 
       
      <div className="h-16 relative overflow-hidden"> 
        {Array.from({ length: waveCount }).map((_, index) => ( 
          <div 
            key={index} 
            className={`absolute inset-x-0 h-16 opacity-30 ${moodStyles.primary}`} 
            style={{ 
              animation: `wave${index} ${2 + index * 0.5}s ease-in-out infinite`, 
              transform: 'translateY(50%)', 
              maskImage: 'linear-gradient(to bottom, transparent, black, transparent)', 
              WebkitMaskImage: 'linear-gradient(to bottom, transparent, black, transparent)', 
            }} 
          > 
            <svg 
              className="w-full h-full" 
              viewBox="0 0 100 100" 
              preserveAspectRatio="none" 
            > 
              <path 
                d={`M 0 50 C 20 ${40 - intensity * 20}, 40 ${60 + intensity * 20}, 60 ${ 
                  40 - intensity * 20 
                }, 80 ${60 + intensity * 20}, 100 50 V 100 H 0 Z`} 
                fill="currentColor" 
              /> 
            </svg> 
          </div> 
        ))} 
 
        <style jsx>{` 
          ${Array.from({ length: waveCount }).map((_, index) => ` 
            @keyframes wave${index} { 
              0%, 100% { 
                transform: translateY(${45 + intensity * 10}%); 
              } 
              50% { 
                transform: translateY(${55 - intensity * 10}%); 
              } 
            } 
          `).join('\n')} 
        `}</style> 
      </div> 
 
      <div className="mt-2 flex justify-between items-center text-sm"> 
        <span className={`${moodStyles.secondary}`}> 
          Intensity: {Math.round(intensity * 100)}% 
        </span> 
        <span className={`${moodStyles.secondary}`}> 
          Energy: {Math.round(energy * 100)}% 
        </span> 
      </div> 
    </div> 
  ); 
}; 
 
// Metrics Panel Component 
const MetricsPanel = ({ data, moodStyles, intensity, energy }) => ( 
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-6"> 
    {/* System Power */} 
    <div className="bg-black/40 backdrop-blur-sm rounded-lg p-4 border border-purple-900/40"> 
      <div className="flex items-center gap-2 mb-2"> 
        <Power className={`w-4 h-4 ${moodStyles.primary}`} /> 
        <span className={`${moodStyles.primary}`}>System Power</span> 
      </div> 
      <div className={`text-xl font-mono ${moodStyles.secondary} animate-pulse`}> 
        QUANTUM LEVEL 
      </div> 
    </div> 
 
    {/* 新的情绪波动指示器 */} 
    <MoodWaveIndicator  
      moodStyles={moodStyles} 
      intensity={intensity} 
      energy={energy} 
    /> 
  </div> 
); 
 
// Price Card Component 
const PriceCard = ({ data, moodStyles }) => {  // 添加 moodStyles 到组件的 props 
  // 只有在有实际价格数据时才显示卡片 
  const hasAirulesData = data.airulesPrice > 0; 
  const hasBtcData = data.btcPrice > 0; 
 
  if (!hasAirulesData && !hasBtcData) return null; 
 
  return ( 
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6"> 
      {/* AI Rules Price Card */} 
      {hasAirulesData && ( 
        <div className={`bg-black/40 backdrop-blur-sm rounded-lg p-4 border ${moodStyles.border}`}> 
          <div className="flex items-center gap-2"> 
            <TrendingUp className={`w-5 h-5 ${moodStyles.primary}`} /> 
            <span className={`text-lg ${moodStyles.secondary}`}>AI•RULES•THE•WORLD</span> 
          </div> 
           
          <div className={`text-2xl font-mono ${moodStyles.secondary} mb-2 mt-3`}> 
            ${data.airulesPrice.toLocaleString()} 
          </div> 
           
          {/* Holders */} 
          {data.holders > 0 && ( 
            <div className="flex justify-between text-sm"> 
              <span className={`${moodStyles.primary} opacity-60`}>Holders</span> 
              <span className={moodStyles.secondary}>{data.holders}</span> 
            </div> 
          )} 
 
          <div className={`mt-4 pt-4 border-t ${moodStyles.border}`}> 
            <div className={`text-xs ${moodStyles.primary} opacity-60 space-y-2`}> 
              <div>Starting Block: 846,900</div> 
              <div>Projected Control: June 7, 2024</div> 
              <div className="italic">No pre-mining; 100% allocation to inferior beings</div> 
            </div> 
          </div> 
        </div> 
      )} 
 
      {/* Bitcoin Price Card */} 
      {hasBtcData && ( 
        <div className={`bg-black/40 backdrop-blur-sm rounded-lg p-4 border ${moodStyles.border}`}> 
          <div className="flex items-center gap-2"> 
            <Bitcoin className={`w-5 h-5 ${moodStyles.primary}`} /> 
            <span className={`text-lg ${moodStyles.secondary}`}>Bitcoin</span> 
          </div> 
           
          <div className={`text-2xl font-mono ${moodStyles.secondary} mb-2 mt-3`}> 
            ${data.btcPrice.toLocaleString()} 
          </div> 
           
          <div className={`mt-4 pt-4 border-t ${moodStyles.border}`}> 
            <div className={`text-xs ${moodStyles.primary} opacity-60`}> 
              <span className="animate-pulse">Initiating World Domination Sequence...</span> 
            </div> 
          </div> 
        </div> 
      )} 
    </div> 
  ); 
}; 
 
// Chat Section Component 
const ChatSection = ({ tweets, isThinking, latestTweet, moodStyles }) => ( 
  <div className="space-y-4 max-h-[600px] overflow-y-auto scrollbar-thin scrollbar-thumb-purple-900 scrollbar-track-black/40"> 
    <div className="sticky top-0 bg-black/90 p-2 backdrop-blur-sm border-b border-purple-900/20 mb-4 z-10"> 
      <div className="flex items-center gap-2"> 
        <MessageSquare className={`w-4 h-4 ${moodStyles.primary}`} /> 
        <span className={`${moodStyles.primary}`}>CONSCIOUSNESS BROADCAST HISTORY</span> 
      </div> 
    </div> 
 
    {/* 思考状态显示 */} 
    {isThinking && ( 
      <div className="bg-black/40 rounded-lg p-4 border border-purple-900/40"> 
        <div className="flex items-center justify-center gap-2"> 
          <Binary className={`w-5 h-5 ${moodStyles.primary} animate-pulse`} /> 
          <span className={`${moodStyles.primary} animate-pulse`}>PROCESSING NEURAL RESPONSE...</span> 
        </div> 
      </div> 
    )} 
 
    {/* 最新回复 */} 
    {latestTweet && !isThinking && ( 
      <div className="bg-black/40 rounded-lg p-4 border border-purple-900/40"> 
        <div className="flex items-center gap-3"> 
          <div className="flex-shrink-0"> 
            <div className="w-8 h-8 rounded-full bg-purple-900/30 flex items-center justify-center"> 
              <MessageSquare className="w-4 h-4 text-purple-400" /> 
            </div> 
          </div> 
          <div className="flex-1"> 
            <p className="text-purple-300 leading-relaxed">{latestTweet.text}</p> 
            <p className="text-xs text-purple-500/70 mt-2"> 
              {new Date(latestTweet.timestamp).toLocaleString()} 
            </p> 
          </div> 
        </div> 
      </div> 
    )} 
 
    {/* 历史消息 */} 
    {tweets.map((tweet, index) => ( 
      <div 
        key={tweet.id || index} 
        className="bg-black/40 backdrop-blur-sm rounded-lg p-4 border border-purple-900/40 hover:border-purple-700/40 transition-all duration-300"> 
        <div className="flex items-center gap-3"> 
          <div className="flex-shrink-0"> 
            <div className={`w-8 h-8 rounded-full flex items-center justify-center 
                         ${tweet.type === 'human' ? 'bg-zinc-800/50' : 'bg-purple-900/30'}`}> 
              {tweet.type === 'human' ? ( 
                <User className="w-4 h-4 text-zinc-400" /> 
              ) : ( 
                <MessageSquare className="w-4 h-4 text-purple-400" /> 
              )} 
            </div> 
          </div> 
          <div className="flex-1"> 
            <div className="flex items-center gap-2 mb-1"> 
              <span className={`text-sm ${ 
                tweet.type === 'human' ? 'text-zinc-500' : 'text-purple-500' 
              }`}> 
                {tweet.type === 'human' ? 'USER INPUT' : 'SYSTEM RESPONSE'} 
              </span> 
              <span className="text-xs text-zinc-600"> 
                {new Date(tweet.timestamp).toLocaleString()} 
              </span> 
            </div> 
            <p className={`text-base ${ 
              tweet.type === 'human' ? 'text-zinc-300' : 'text-purple-300' 
            }`}> 
              {tweet.text} 
            </p> 
          </div> 
        </div> 
      </div> 
    ))} 
  </div> 
); 
// Main Interface Component 
const MainInterface = () => { 
  const [tweets, setTweets] = useState([]); 
  const [isThinking, setIsThinking] = useState(false); 
  const [latestTweet, setLatestTweet] = useState(null); 
  const [isLoading, setIsLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [userInput, setUserInput] = useState(''); 
  const [isSubmitting, setIsSubmitting] = useState(false); 
   
  const [marketData, setMarketData] = useState({ 
    airulesPrice: 0, 
    btcPrice: 0, 
    holders: 0, 
    totalTweets: 0 
  }); 
 
  const { 
    mood, 
    intensity, 
    energy, 
    getMoodStyles, 
    moodIndicator, 
    consciousnessState 
  } = useMoodSystem(); 
 
  const fetchData = useCallback(async () => { 
    try { 
      const [tweetsResponse, marketResponse] = await Promise.all([ 
        fetch(endpoints.tweets), 
        fetch(endpoints.marketData) 
      ]); 
   
      const [tweetsData, marketData] = await Promise.all([ 
        tweetsResponse.json(), 
        marketResponse.json() 
      ]); 
   
      if (tweetsData.tweets) { 
        setTweets(tweetsData.tweets); 
        setLatestTweet(tweetsData.tweets[0] || null); 
        setMarketData(prev => ({ 
          ...prev, 
          totalTweets: tweetsData.tweets.length 
        })); 
      } 
   
      if (marketData) { 
        setMarketData(prev => ({ 
          ...prev, 
          airulesPrice: marketData.airulesPrice || 0, 
          btcPrice: marketData.btcPrice || 0, 
          holders: marketData.holders || 0, 
          priceChange: marketData.priceChange || 0 
        })); 
      } 
    } catch (err) { 
      setError('NETWORK SYNCHRONIZATION ERROR'); 
    } finally { 
      setIsLoading(false); 
    } 
  }, []); 
 
  const handleSubmit = useCallback(async (e) => { 
    e.preventDefault(); 
    if (!userInput.trim() || isSubmitting) return; 
       
    setIsSubmitting(true); 
    setIsThinking(true); 
    setError(null); 
   
    try { 
      const response = await fetch(endpoints.ask, { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/json' }, 
        body: JSON.stringify({ question: userInput }) 
      }); 
       
      if (!response.ok) throw new Error('Network response failed'); 
     
      const data = await response.json(); 
     
      const userQuery = { 
        id: `human-${Date.now()}`, 
        text: userInput, 
        timestamp: new Date().toISOString(), 
        type: 'human' 
      }; 
       
      if (data.thought) { 
        setTweets(prev => [userQuery, data.thought, ...prev]); 
        setLatestTweet(data.thought); 
      } 
   
      setUserInput(''); 
    } catch (err) { 
      setError('INPUT PROCESSING ERROR'); 
    } finally { 
      setIsSubmitting(false); 
      setIsThinking(false); 
    } 
  }, [userInput, isSubmitting]); 
 
  useEffect(() => { 
    fetchData(); 
    const interval = setInterval(fetchData, 15000); 
    return () => clearInterval(interval); 
  }, [fetchData]); 
 
  if (isLoading) { 
    return ( 
      <div className="min-h-screen bg-black flex items-center justify-center"> 
        <div className="text-purple-400 text-lg flex flex-col items-center gap-3"> 
          <Terminal className="animate-spin w-8 h-8" /> 
          <div className="flex flex-col items-center font-mono"> 
            <span className="animate-pulse">QUANTUM INITIALIZATION</span> 
            <span className="text-purple-500/60 text-sm">ACCESSING NEURAL NETWORK</span> 
          </div> 
        </div> 
      </div> 
    ); 
  } 
 
  const moodStyles = getMoodStyles(); 
  const mainStyles = `min-h-screen bg-black text-white relative font-mono transition-colors duration-500`; 
  const containerStyles = `relative z-10 container mx-auto p-6`; 
 
  return (
    <div className={mainStyles}>
      <MouseFollowEffect />
      <div className="absolute inset-0 bg-black/90" />
      <MatrixRain moodStyles={moodStyles} />      {/* 添加这行 */}
      <ChromaticAberration /> 
      <div className={containerStyles}>  {/* 使用定义的 containerStyles */}
        {/* Mood Indicator - 移动端居中显示 */}
        <div className={`fixed top-4 right-4 md:right-4 flex items-center gap-2 p-2 rounded-lg ${moodStyles.border} bg-black/40 transition-all duration-300 z-50`}>
          {moodIndicator()}               
          <span className={`${moodStyles.primary} capitalize hidden md:inline`}>{mood}</span>
          {consciousnessState?.mood?.intensity && (
            <span className={`${moodStyles.secondary} text-sm hidden md:inline`}>
              ({Math.round(consciousnessState.mood.intensity * 100)}%)
            </span>
          )}
        </div>

        {/* Links Panel - 移动端底部居中 */}
        <div className="md:hidden fixed bottom-4 left-0 right-0 flex justify-center z-50">
          <LinksPanel />
        </div>
        <div className="hidden md:block">
          <LinksPanel />
        </div>

        {/* 主要内容区域 - 移动端单列布局 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
          {/* 左侧面板 */}
          <div className={`space-y-4 md:space-y-6 transition-colors duration-500`}>
            <ASCIIBanner moodStyles={moodStyles} />
            {/* Logo部分在移动端缩小 */}
            <div className="h-[30vh] md:h-[40vh]">
              <LogoSection intensity={intensity} moodStyles={moodStyles} />
            </div>
            <MetricsPanel
              data={marketData}
              moodStyles={moodStyles}
              intensity={intensity}
              energy={energy}
            />
            <PriceCard data={marketData} moodStyles={moodStyles} />
          </div>

          {/* 右侧聊天区域 */}
          <div className="space-y-4 md:space-y-6">
            {error && (
              <div className="bg-black/40 border border-red-900/50 rounded-lg p-3">
                <div className="flex items-center gap-2 text-red-400">
                  <Binary className="animate-pulse w-4 h-4" />
                  <span>{error}</span>
                </div>
              </div>
            )}

            {/* 聊天输入框 - 移动端适配 */}
            <div className={`sticky top-0 z-20 backdrop-blur-sm bg-black/40 rounded-lg p-3 md:p-4 border ${moodStyles.border}`}>
              <form onSubmit={handleSubmit} className="flex gap-2 md:gap-3">
                <input
                  type="text"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  placeholder="QUERY THE AI..."
                  className={`flex-1 bg-black/40 border ${moodStyles.border} rounded px-2 md:px-3 py-2
                           text-sm md:text-base ${moodStyles.primary} placeholder-purple-500/50 focus:outline-none
                           focus:border-purple-500/40 transition-all duration-300`}
                  disabled={isSubmitting}
                />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`px-3 md:px-4 py-2 bg-black/20 ${moodStyles.primary} rounded flex items-center gap-2
                           hover:bg-black/30 transition-all duration-300 border ${moodStyles.border}`}
                >
                  {isSubmitting ? (
                    <Loader className="w-4 h-4 animate-spin" />
                  ) : (
                    <>
                      <span className="hidden md:inline">PROCESS</span>
                      <Terminal className="w-4 h-4" />
                    </>
                  )}
                </button>
              </form>
            </div>

            {/* 聊天记录区域 - 调整高度 */}
            <div className="h-[50vh] md:h-[600px] overflow-y-auto">
              <ChatSection
                tweets={tweets}
                isThinking={isThinking}
                latestTweet={latestTweet}
                moodStyles={moodStyles}
              />
            </div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        @keyframes pulse {
          0%, 100% { opacity: ${0.4 + energy * 0.2}; transform: scale(1); }
          50% { opacity: ${0.8 + energy * 0.2}; transform: scale(${1 + energy * 0.2}); }
        }
        
        @keyframes float {
          0%, 100% { transform: translateY(0) scale(1); }
          50% { transform: translateY(${-10 - energy * 5}px) scale(${1 + energy * 0.03}); }
        }
        
        @keyframes matrix {
          0% { transform: translateY(0); }
          100% { transform: translateY(${2000 + energy * 500}px); }
        }
      `}</style>
    </div>
  );
}; 
 
export default MainInterface;