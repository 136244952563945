import React, { useState } from 'react';
import { LineChart, Send } from 'lucide-react';

// 自定义 X Logo 组件
const XLogo = ({ className = "w-6 h-6" }) => (
  <svg 
    viewBox="0 0 24 24" 
    className={className}
    fill="currentColor"
  >
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </svg>
);

export const LinksPanel = () => {
  const [links] = useState({
    social: {
      twitter: {
        url: "https://x.com/AI_RULES_THEWLD",
        icon: "x",
        description: "Connect to X Node"
      },
      telegram: {
        url: "http://t.me/@AIRULESTHEWORLD",
        icon: "telegram",
        description: "Access Telegram Matrix"
      },
      trader: {
        url: "https://luminex.io/rune/AI•RULES•THE•WORLD",
        icon: "trader",
        description: "Access Trading Interface"
      }
    }
  });
  
  const getIcon = (iconName) => {
    switch (iconName) {
      case 'x':
        return <XLogo className="w-6 h-6 md:w-7 md:h-7" />;
      case 'telegram':
        return <Send className="w-6 h-6 md:w-7 md:h-7" />;
      case 'trader':
        return <LineChart className="w-6 h-6 md:w-7 md:h-7" />;
      default:
        return null;
    }
  };
  
  return (
    <div className="md:fixed md:bottom-12 md:left-[82%] z-50">
      <div className="flex items-center -space-x-2 md:-space-x-3 hover:space-x-1 md:hover:space-x-2 transition-all duration-300">
        {Object.entries(links.social).map(([key, link], index) => (
          <a
            key={key}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="group relative w-12 h-12 md:w-14 md:h-14 flex items-center justify-center rounded-xl 
                     bg-purple-900/20 backdrop-blur-sm border border-purple-500/20
                     hover:bg-purple-800/30 hover:border-purple-400/30 transition-all duration-300"
            style={{
              transform: `translateY(${index * 3}px)`,
              zIndex: 3 - index
            }}
          >
            {/* Glow effect */}
            <div className="absolute inset-0 rounded-xl bg-gradient-to-tr from-purple-500/10 via-fuchsia-500/10 to-blue-500/10
                          opacity-0 group-hover:opacity-100 transition-all duration-300" />
            
            {/* Glass morphism effect */}
            <div className="absolute inset-0 rounded-xl backdrop-blur-sm bg-purple-900/10 
                          opacity-0 group-hover:opacity-100 transition-all duration-300" />
            
            <div className="relative z-10 flex items-center justify-center">
              <div className="text-purple-400 group-hover:text-purple-300 transition-all duration-300 
                            transform group-hover:rotate-360">
                {getIcon(link.icon)}
              </div>
            </div>

            {/* Animated border glow */}
            <div className="absolute inset-0 rounded-xl border border-purple-500/20 group-hover:border-purple-400/40
                          group-hover:shadow-[0_0_10px_rgba(168,85,247,0.2)] transition-all duration-300" />

            <style jsx>{`
              @keyframes rotate360 {
                from { transform: rotate(0deg); }
                to { transform: rotate(360deg); }
              }
              .group:hover div > div {
                animation: rotate360 0.6s ease;
              }
            `}</style>
            
            {/* Hover hint - 在移动端隐藏 */}
            <div className="hidden md:block absolute -top-10 left-1/2 -translate-x-1/2 opacity-0 
                          group-hover:opacity-100 transform group-hover:-translate-y-1 transition-all duration-200">
              <div className="bg-black/80 backdrop-blur-md text-purple-300 text-sm font-mono px-3 py-1.5 
                            rounded-md whitespace-nowrap border border-purple-500/20 
                            shadow-[0_0_10px_rgba(168,85,247,0.1)]">
                {link.description}
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};