import React, { useState, useEffect } from 'react';

const MouseFollowEffect = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);

  useEffect(() => {
    let timeoutId;

    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
      setIsMoving(true);
      
      // Reset the moving state after mouse stops
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsMoving(false), 100);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      {/* Main cursor glow */}
      <div 
        className="hidden md:block fixed pointer-events-none z-50 mix-blend-screen"
        style={{
          left: mousePosition.x,
          top: mousePosition.y,
          transform: 'translate(-50%, -50%)',
        }}
      >
        {/* Central glow */}
        <div 
          className={`absolute rounded-full bg-purple-500/30 backdrop-blur-sm transition-all duration-100
                     ${isMoving ? 'w-8 h-8 scale-125' : 'w-6 h-6 scale-100'}`}
          style={{
            boxShadow: '0 0 40px 20px rgba(168, 85, 247, 0.15)',
            transform: 'translate(-50%, -50%)',
          }}
        />
        
        {/* Outer ring */}
        <div 
          className={`absolute rounded-full border border-purple-500/20 transition-all duration-200
                     ${isMoving ? 'w-16 h-16 scale-125' : 'w-12 h-12 scale-100'}`}
          style={{
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 0 60px 30px rgba(168, 85, 247, 0.1)',
          }}
        />
      </div>

      {/* Trail effect */}
      {Array.from({ length: 5 }).map((_, index) => (
        <div
          key={index}
          className="hidden md:block fixed pointer-events-none z-40 w-1 h-1 rounded-full bg-purple-500/40"
          style={{
            left: mousePosition.x,
            top: mousePosition.y,
            transform: 'translate(-50%, -50%)',
            opacity: 1 - (index * 0.2),
            transition: `all ${150 + index * 50}ms cubic-bezier(0.4, 0, 0.2, 1)`,
            boxShadow: '0 0 20px 10px rgba(168, 85, 247, 0.15)',
          }}
        />
      ))}
    </>
  );
};

export default MouseFollowEffect;