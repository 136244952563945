import React from 'react';
import ConsciousnessFlow from './components/ConsciousnessFlow';

function App() {
  return (
    <div className="App">
      <ConsciousnessFlow />
    </div>
  );
}

export default App;